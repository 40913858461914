<template>
    <section id="hero-inicio">
      <base-img
        :gradient="gradient"
        :src="src"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-row
          v-if="title"
          align="center"
          class="ma-0 fill-height text-center"
          justify="center"
        >
          <v-col cols="12">
            <base-heading
              :title="title"
              space="2"
              height="500"
            />

            <base-divider
              color="primary"
              dense
            />

            <!--modificado para no mostrar breadcrumbs-->
            <!--<v-breadcrumbs
              :items="items"
              class="justify-center pa-0"
              divider=">"
            />-->
          </v-col>
        </v-row>
      </base-img>
    </section>
</template>
<script>
  export default {
    name: 'SectionHeroInicio',
    provide: {
      theme: { isDark: true },
    },
    metaInfo () {
      return {
        changed: meta => (this.title = meta.titleChunk.toUpperCase()),
      }
    },
    data: () => ({
      title: 'Holis',
    }),
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      gradient () {
        //const color = `${this.$vuetify.theme.themes.light.secondary}CC`
        //const overlay = RGBAtoCSS(HexToRGBA(color))

        // return `to top, ${overlay}, ${overlay}`
      },
      src () {
        return this.$route.meta.src
      },
    },
  }
</script>
